<template>
  <div>
    <Welcome />
    <Tonic />
  </div>
</template>

<style>
</style>

<script>
import Tonic from "@/components/Tonic";
import Welcome from "@/components/Welcome";
export default {
  name: "Home",
  components: {
    Tonic,
    Welcome,
  },
};
</script>
