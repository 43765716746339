<template>
  <div class="contact-overlay" :class="{ hidden: isHidden }">
    <div class="contact">
      <form>
        <div class="input-wrapper">
          <label for="name">Your name</label>
          <input
            type="text"
            v-model="name"
            name="name"
            placeholder="Type name"
          />
        </div>
        <div class="input-wrapper">
          <label for="message">Your feedback</label>
          <textarea
            name="message"
            v-model="message"
            cols="60"
            rows="5"
            placeholder="Type feedback"
          >
          </textarea>
        </div>
        <div class="button-wrapper">
          <button type="button" @click="toggleHidden" class="primary">
            Send feedback
          </button>
        </div>
      </form>
    </div>
    <button type="button" class="icon-button float" @click="toggleHidden" />
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      name: "",
      message: "",
    };
  },
  props: {
    hideForm: Boolean,
  },
  computed: {
    isHidden() {
      return this.hideForm;
    },
  },
  methods: {
    toggleHidden() {
      const body = {
        // from: { email: "s2simulator@tonic.com" },
        subject: `Feedback from: ${this.name}`,
        text: this.message,
        html: this.message,
      };

      fetch(
        "https://vx6aevbiyzyqv7vfqv45a24wrm0eonhh.lambda-url.eu-west-1.on.aws",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "no-cors",
          body: JSON.stringify(body),
        }
      );

      this.$emit("childToParent", !this.isHidden);
    },
  },
};
</script>

<style scoped>
.contact-overlay {
  position: absolute;
  height: 100vw;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.contact form {
  background-color: #eaca8f;
}

.contact-overlay.hidden {
  display: none;
}

.contact button.primary {
  background-image: url(../assets/contact/button/top-left.svg),
    url(../assets/contact/button/top-right.svg),
    url(../assets/contact/button/bottom-left.svg),
    url(../assets/contact/button/bottom-right.svg),
    url(../assets/contact/button/top.svg),
    url(../assets/contact/button/left.svg),
    url(../assets/contact/button/right.svg),
    url(../assets/contact/button/bottom.svg);
}

.input-wrapper + .input-wrapper {
  margin-top: 8px;
}

.button-wrapper {
  text-align: right;
  margin-top: 8px;
}

.contact {
  position: absolute;
  top: 5%;
  right: 5%;
  padding: 32px;
  background-image: url(../assets/contact/top-left.svg),
    url(../assets/contact/top-right.svg), url(../assets/contact/bottom-left.svg),
    url(../assets/contact/bottom-right.svg), url(../assets/contact/top.svg),
    url(../assets/contact/left.svg), url(../assets/contact/right.svg),
    url(../assets/contact/bottom.svg);
  background-position: top left, top right, bottom left, bottom right, top left,
    left top, right top, bottom left;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x,
    repeat-y, repeat-y, repeat-x;
  background-size: 32px, /*Breite Höhe*/ 32px, 32px, 32px, auto 32px, 32px auto,
    32px auto, auto 32px;
}
</style>
 