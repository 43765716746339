<template>
  <header>
    <h3>
      <router-link to="/">S2Simulator</router-link>
    </h3>
    <div v-show="showButtons" class="multi-buttons">
      <button type="button" @click="prefill">
        <span>Prefill</span>
      </button>
      <button type="button" class="primary" @click="trigger">
        <span>Click Ad</span>
      </button>
      <button type="button" @click="reset">
        <span>Reset</span>
      </button>
    </div>
    <a href="#" @click="giveFeedback">Give feedback</a>
    <ContactUs :hideForm="isHidden" v-on:childToParent="onGiveFeedbackClick" />
  </header>
</template>

<style>
</style>

<script>
import ContactUs from "@/components/ContactUs";
export default {
  name: "Header",
  data: () => ({
    isHidden: true,
  }),
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: () => ({ showButtons: false }),
    },
  },
  components: { ContactUs },
  methods: {
    onGiveFeedbackClick(value) {
      this.isHidden = value;
    },
    giveFeedback() {
      this.isHidden = !this.isHidden;
    },
    prefill() {
      this.$emit("prefill");
    },
    trigger() {
      this.$emit("trigger");
    },
    reset() {
      this.$emit("reset");
    },
  },
};
</script>
