<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-content">
        <h1>My TrackSys campaign</h1>
      </div>
    </div>
    <div class="card-content">
      <div>
        <div>
          <label>Domain for sending me traffic</label>
          <p>
            {{ link }}
          </p>
        </div>

        <div class="infobox">
          <p class="info-title">I know these parameters. Use them to send me additional things:</p>
          <p>adTitle={}, eventType={}, campaignName={} and webSite={}</p>
        </div>
      </div>
      <span class="divider"></span>
      <div>
        <h2>TONIC. sent me these things so far:</h2>
        <div v-if="items.length === 0">
          <p>Nothing send yet :(</p>
        </div>
        <div v-if="items.length !== 0" class="table">
          <div class="event">
            <div class="header">Event</div>
            <div v-for="(item, index) in items" :key="index">
              <span class="colored-etype">{{ item.event }}</span></div>
          </div>
          <div class="campaign-name">
            <div class="header">Campaign name</div>
            <div v-for="(item, index) in items" :key="index">
              <span class="colored-cname">{{ item.campaign_name || "-" }}</span></div>
          </div>
          <div class="title">
            <div class="header">Ad title</div>
            <div v-for="(item, index) in items" :key="index">
              <span class="colored-adtitle">{{ item.title  || "-" }}</span></div>
          </div>
          <div class="website">
            <div class="header">Website</div>
            <div v-for="(item, index) in items" :key="index">
              <span class="colored-place">{{ item.website  || "-" }}</span></div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<style>

.table{
  display: flex;
  border: 2px solid rgba(0, 0, 0, 0.24);
  align-items: stretch;
  font-size: 1.5em;
  background-color: #8aa289;
}

.table .header{
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 2px solid rgba(0, 0, 0, 0.24);
  font-size: 1em;
}

.table > div{
  border-right: 2px solid rgba(0, 0, 0, 0.24);
  margin-right: -2px;
  flex-grow: 1;
  min-width: 25%;
}
.table > div:last-child{
  border-right: none;
}

.table div div {
  padding: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>

<script>
const props = {
  items: {
    type: Array,
    required: false,
  },
};
export default {
  name: "Card3",
  props,
  data: () => ({
    link: "https://my-tracking-sys.com/",
    headers: [
      {text: "Event t.", value: "event"},
      {text: "Campaign name", value: "campaign_name"},
      {text: "Ad title", value: "title"},
      {text: "Website", value: "website"},
    ],
  }),
  methods: {},
};
</script>
