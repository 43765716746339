<template>
  <div class="background-outer">
    <main>
      <Header
        :showButtons="true"
        v-on:prefill="prefill"
        v-on:trigger="trigger"
        v-on:reset="reset"
      />
      <div class="background-inner flexing">
        <card1
          :toniCampaign="tc"
          :networkCampaign="nc"
          v-on:childToParent="onCard1Change"
          class="section"
        />
        <card2
          :networkCampaign="nc"
          v-on:childToParent="onCard2Change"
          class="section"
        />
        <card3 :items="items" class="section" />
      </div>
    </main>
  </div>
</template>

<style>
.flexing {
  display: flex;
  height: calc(100vh - 64px);
  flex-direction: row;
}

.section {
  flex: 1;
  position: relative;
  transition: 0.5s ease;
}

.section:hover,
.section:active,
.section:focus {
  flex: 3;
}

main {
  padding: 24px;
  height: 100%;
  box-sizing: border-box;
}

header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;

  z-index: 1000;
  justify-content: space-between;
}

header h3 {
  padding-top: 2px;
  padding-left: 20px;
}

.multi-buttons {
  padding: 8px;
  background-color: #7d927c;
  display: flex;

  background-image: url(../assets/buttonwrapper/top-left.svg),
    url(../assets/buttonwrapper/top-right.svg),
    url(../assets/buttonwrapper/bottom-left.svg),
    url(../assets/buttonwrapper/bottom-right.svg),
    url(../assets/buttonwrapper/left.svg),
    url(../assets/buttonwrapper/right.svg), url(../assets/buttonwrapper/top.svg);
  background-position: top left, top right, bottom left, bottom right, top left,
    top right, top left;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-y,
    repeat-y, repeat-x;
  background-size: 9px 6px, 9px 6px, 9px 11px, 9px 11px, 9px auto, 9px auto,
    auto 6px;
}

.multi-buttons button + button {
  margin-left: 4px;
}
</style>

<script>
import Card1 from "@/components/cards/Card1.vue";
import Card2 from "@/components/cards/Card2.vue";
import Card3 from "@/components/cards/Card3.vue";
import Header from "@/components/shared/Header";

export default {
  name: "Tonic",
  data: () => ({
    tc: {
      redirect: "",
      view: "",
      revenue: "",
    },
    nc: {
      campaign_name: "",
      adtitle: "",
      place: "",
      domain: "",
      parameters: "",
    },
    items: [],
  }),
  components: {
    Card1,
    Card2,
    Card3,
    Header,
  },
  methods: {
    onCard1Change(value) {
      this.tc = value;
      this.tc.url = this.replaceParams(
        this.tc.redirect,
        this.nc.campaign_name,
        this.nc.adtitle
      );
    },
    onCard2Change(value) {
      this.nc = value;
      this.tc.url = this.replaceParams(
        this.tc.redirect,
        this.nc.campaign_name,
        this.nc.adtitle
      );
    },
    trigger() {
      this.items.push({
        event: "Redirect",
        campaign_name: this.tc.redirect ? this.nc.campaign_name : "",
        title: this.tc.redirect ? this.nc.adtitle : "",
        website: this.tc.redirect ? this.nc.place : "",
      });
      this.items.push({
        event: "View",
        campaign_name: this.tc.view ? this.nc.campaign_name : "",
        title: this.tc.view ? this.nc.adtitle : "",
        website: this.tc.view ? this.nc.place : "",
      });
      this.items.push({
        event: "Revenue",
        campaign_name: this.tc.revenue ? this.nc.campaign_name : "",
        title: this.tc.revenue ? this.nc.adtitle : "",
        website: this.tc.revenue ? this.nc.place : "",
      });
    },
    prefill() {
      this.tc = {
        redirect:
          "https://my-tracking-sys.com/?campaignName={campaign_name}&adtitle={adtitle}&eventType={type}",
        view: "https://my-tracking-sys.com/?campaignName={campaign_name}&adtitle={adtitle}&eventType={type}",
        revenue:
          "https://my-tracking-sys.com/?campaignName={campaign_name}&adtitle={adtitle}&eventType={type}",
      };
      this.nc = {
        campaign_name: "My Example Campaign",
        adtitle: "My Very Cool Ad Title",
        place: "Unicorns24",
        domain: "https://123456.unicortastic.com/",
        parameters:
          "?network=myNetwork&adtitle={title}&site={site}&subid1={campaign_name}",
      };
    },
    reset() {
      this.tc = {
        redirect: "",
        view: "",
        revenue: "",
      };
      this.nc = {
        campaign_name: "",
        adtitle: "",
        place: [""],
        domain: "",
      };
      this.items = [];
    },
    replaceParams(
      redirect,
      campaign_name,
      adtitle,
      type = "redirect",
      revenue,
      keyword
    ) {
      if (redirect) {
        const computedParams = redirect
          .replace("{campaign_name}", campaign_name)
          .replace("{adtitle}", adtitle)
          .replace("{type}", type)
          .replace("{revenue}", revenue)
          .replace("{keyword}", keyword);
        return `${encodeURI(computedParams)}`;
      }
    },
  },
};
</script>
