<template>
  <div class="welcome-overlay" :class="{ hidden: isHidden }">
    <div class="corgi">
      <div class="avatar">
        <img
          src="../assets/avatar.png"
          alt="A pixel-style painting of a smiling corgi dog."
        />
      </div>
      <div class="chat-window">
        <label>Tonici</label>
        <div>
          <div class="dialog">
            <p v-for="(text, index) in dialogs[shownDialog].text" :key="index">
              {{ text }}
            </p>
            <p class="dialog-count">
              {{ Number(shownDialog) + 1 }}/{{ this.dialogAmount }}
            </p>
          </div>
          <div class="welcome-buttons">
            <div class="welcome-button-wrapper">
              <button type="button" @click="toggleHidden">
                <span>{{ dialogs[shownDialog].cancelButton }}</span>
              </button>
            </div>
            <div>
              <div
                class="welcome-button-wrapper"
                :style="shownDialog > 0 || 'display: none;'"
              >
                <button type="button" @click="lastDialog">
                  <span>{{ dialogs[shownDialog].backButton }}</span>
                </button>
              </div>
              <div class="welcome-button-wrapper">
                <button type="button" @click="nextDialog" class="primary">
                  <span>{{ dialogs[shownDialog].nextButton }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="icon-button float" @click="toggleHidden" />
  </div>
</template>

<style scoped>
.welcome-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.welcome-overlay.hidden {
  display: none;
}

.corgi {
  display: flex;
  align-items: flex-end;
  width: auto;
}

.corgi .avatar {
  height: 500px;
  width: 400px;
}

.corgi .avatar img {
  height: 100%;
  width: 100%;
}

.corgi .chat-window {
  flex-grow: 1;
  background-color: #7d927c;
  border: 4px solid rgba(27, 70, 60, 1);
  padding: 24px;

  position: relative;
}

.corgi .welcome-buttons {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.corgi .welcome-buttons .welcome-button-wrapper {
  position: relative;
  width: auto;
  display: inline-block;
}

.corgi button {
  position: static;
}

.corgi .chat-window > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.corgi .chat-window p {
  width: 75ch;
  font-size: 2em;
  margin-top: 8px;
  margin-bottom: 8px;
}

.corgi .chat-window label {
  position: absolute;
  top: -24px;
  left: 16px;
  font-size: 2em;
}

.dialog-count {
  width: 100%;
  text-align: center;
  color: #546453;
}
</style>
<script>
import json from "../assets/dialogs.json";

export default {
  name: "Welcome",
  data: () => ({
    isHidden: false,
    shownDialog: 0,
    dialogs: json,
    dialogAmount: Object.keys(json).length,
  }),
  methods: {
    nextDialog() {
      if (this.shownDialog === this.dialogAmount - 1) {
        this.toggleHidden();
      } else {
        this.shownDialog++;
        localStorage.shownDialog = this.shownDialog;
      }
    },
    lastDialog() {
      if (this.shownDialog > 0) {
        this.shownDialog--;
        localStorage.shownDialog = this.shownDialog;
      }
    },
    toggleHidden() {
      this.isHidden = !this.isHidden;
    },
  },
  mounted() {
    if (localStorage.getItem("shownDialog")) {
      this.shownDialog = localStorage.shownDialog;
      if (localStorage.shownDialog >= this.dialogAmount - 1) {
        this.toggleHidden();
      }
    }
  },
};
</script>
