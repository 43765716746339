<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-content">
        <h1>My TONIC. campaign</h1>
      </div>
    </div>
    <div class="card-content">
      <div>
        <label>Campaign name</label>
        <p>
          {{ cName }}
        </p>
      </div>

      <span class="divider"></span>

      <div>
        <div>
          <!-- todo copy button -->
          <label>Tracking domain</label>
          <p>
            {{ trackingDomain }}
          </p>
        </div>
        <div class="infobox">
          <p class="info-title">
            I know these parameters. Use them to send me additional things:
          </p>
          <p>network={}, site={}, adtitle={} and subid1={}</p>
        </div>
      </div>

      <span class="divider"></span>

      <div>
        <h2>S-to-S call configuration:</h2>
        <div class="infobox">
          <p class="info-title">
            I can fill these placeholders. Use them to fill parameters TrackSys
            offers:
          </p>
          <p>{campaign_name}, {adtitle}, {type}, {revenue} and {keyword}.</p>
        </div>
        <div>
          <div class="input-wrapper">
            <label for="redirect">Redirect</label>
            <input
              type="text"
              id="redirect"
              class="nes-input"
              v-on:keyup="emitToTonic"
              v-model="toniCampaign.redirect"
            />
            <p class="helpertext">
              Event type «<span class="colored-etype">redirect</span>».
              Triggered when the tracking link is visited.
            </p>
          </div>
          <div class="input-wrapper">
            <label for="view">View</label>
            <input
              type="text"
              id="view"
              class="nes-input"
              v-on:keyup="emitToTonic"
              v-model="toniCampaign.view"
            />
            <p class="helpertext">
              Event type «<span class="colored-etype">view</span>». Triggered
              after the redirect callback when page is fully loaded.
            </p>
          </div>
          <div class="input-wrapper">
            <label for="view">Pre-Estimated Revenue</label>
            <input
              type="text"
              id="revenue"
              class="nes-input"
              v-on:keyup="emitToTonic"
              v-model="toniCampaign.revenue"
            />
            <p class="helpertext">
              Event type «<span class="colored-etype">preestimated_revenue</span
              >». Available directly after the click.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="send-link-box">
      <span class="tooltip">
        <p>This is what I send TrackSys once I got data from MyNetwork</p>
        <img
          src="../../assets/arrow-down.svg"
          alt="An arrow facing downwards"
        />
      </span>
      <div>
        <p class="url" v-html="url('redirect', toniCampaign.redirect)"></p>
        <p class="url" v-html="url('view', toniCampaign.view)"></p>
        <p class="url" v-html="url('revenue', toniCampaign.revenue)"></p>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
const props = {
  toniCampaign: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  networkCampaign: {
    type: Object,
    required: false,
    default: () => ({}),
  },
};
export default {
  name: "Card1",
  props,
  data: () => ({
    cName: "MyNetwork_DE_Unicorns",
    trackingDomain: "https://123456.unicortastic.com/",
  }),
  computed: {
    url() {
      return (type, url) => {
        if (url) {
          const computedParams = url
            .replace(
              "{campaign_name}",
              `<span class="colored-cname">${encodeURI(
                this.networkCampaign.campaign_name
              )}</span>`
            )
            .replace(
              "{adtitle}",
              `<span class="colored-adtitle">${encodeURI(
                this.networkCampaign.adtitle
              )}</span>`
            )
            .replace("{type}", `<span class="colored-etype">${type}</span>`)
            .replace(
              "{revenue}",
              `<span class="colored-etype">${encodeURI(
                this.toniCampaign.revenue
              )}</span>`
            );
          return computedParams;
        } else {
          return `Nothing to sent yet for event type ${type}.`;
        }
      };
    },
  },
  methods: {
    emitToTonic() {
      this.$emit("childToParent", this.toniCampaign);
    },
  },
};
</script>
