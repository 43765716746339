<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-content">
        <h1>MyNetwork. campaign</h1>
      </div>
    </div>
    <div class="card-content">
      <div class="input-wrapper colored-cname">
        <label for="campaign-name">Campaign name</label>
        <input
          type="text"
          id="campaign-name"
          class="nes-input"
          v-on:keyup="emitToTonic"
          v-model="networkCampaign.campaign_name"
        />
      </div>
      <div class="input-wrapper colored-adtitle">
        <label for="ad-title">Ad title</label>
        <input
          type="text"
          id="ad-title"
          class="nes-input"
          v-on:keyup="emitToTonic"
          v-model="networkCampaign.adtitle"
        />
      </div>
      <div class="input-wrapper colored-place">
        <label for="place">Place where the ad is placed</label>
        <select v-model="networkCampaign.place">
          <option
            id="place"
            v-for="place in places"
            v-bind:value="place.value"
            :key="place.value"
          >
            {{ place.text }}
          </option>
        </select>
      </div>
      <div class="input-wrapper">
        <label for="tracking-domain">Tracking domain</label>
        <input
          type="text"
          id="tracking-domain"
          class="nes-input"
          v-on:keyup="emitToTonic"
          v-model="networkCampaign.domain"
        />
      </div>
      <div>
        <div class="input-wrapper">
          <label for="tracking-parameters">Tracking parameters</label>
          <input
            type="text"
            id="tracking-parameters"
            class="nes-input"
            v-model="networkCampaign.parameters"
          />
        </div>
        <div class="infobox">
          <p class="info-title">I know these placeholders for the tracking parameters:</p>
          <p>{title}, {site}, {type} and {campaign_name}.</p>
        </div>
      </div>
    </div>
    <div class="send-link-box">
      <span class="tooltip">
        <p>The «Tracking link» (tracking domain + tracking parameters) is what I’ll
        send to TONIC. once a click happens</p>
        <img src="../../assets/arrow-down.svg" alt="An arrow facing downwards" />
      </span>
      <div>
         <p class="url" v-html="url"> </p>
      </div>
    </div>
  </div>
</template>

<script>
const props = {
  networkCampaign: {
    type: Object,
    required: false,
    default: () => ({}),
  },
};
export default {
  name: "Card2",
  props,
  data: () => ({
    places: [
      { text: "Unicorns24", value: "Unicorns24" },
      { text: "News24/7", value: "News24/7" },
      { text: "Booking for Unicorns", value: "Booking for Unicorns" },
      { text: "RainbowSale", value: "RainbowSale" },
      { text: "Foo bar", value: "Foo bar" },
    ],
  }),
  computed: {
    url() {
      if (this.networkCampaign.domain && this.networkCampaign.parameters) {
        const computedParams = this.networkCampaign.parameters
          .replace(
            "{title}",
            `<span class="colored-adtitle">${encodeURI(
              this.networkCampaign.adtitle
            )}</span>`
          )
          .replace(
            "{campaign_name}",
            `<span class="colored-cname">${encodeURI(
              this.networkCampaign.campaign_name
            )}</span>`
          )
          .replace(
            "{site}",
            `<span class="colored-place">${encodeURI(
              this.networkCampaign.place
            )}</span>`
          )
          .replace("{type}", "redirect");
        return `${this.networkCampaign.domain}${computedParams}`;
      } else {
        return "Fill the inputs for generating the tracking link.";
      }
    },
  },

  methods: {
    emitToTonic() {
      this.$emit("childToParent", this.networkCampaign);
    },
  },
};
</script>
